<template>
  <div>
    <!--begin::customer-->
    <div>

      <h3 class="card-label">
        {{ $t('MENU.item_label_management') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>


      <div class="row">
        <div class="col-md-12">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-4 mb-4">
                <label>{{ $t('size') }}</label>
                <select @change="setHeightWidth" id="size" v-model="size"
                        :class="validation && validation.size ? 'is-invalid' : ''" class="custom-select"
                        name="">
                  <option v-for="(row, index) in size_option" :key="'a'+index" :value="row.id">{{ row.label }}</option>
                </select>
              </div>
              <div class="col-lg-4 mb-4">
                <label>{{ $t('height') }}</label>
                <input readonly v-model="data.height" :class="validation && validation.height ? 'is-invalid' : ''" class="form-control"
                       type="number">
                <span v-if="validation && validation.height"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.height[0] }}
                </span>
              </div>
              <div class="col-lg-4 mb-4">
                <label>{{ $t('width') }}</label>
                <input readonly v-model="data.width" :class="validation && validation.width ? 'is-invalid' : ''" class="form-control"
                       type="number">
                <span v-if="validation && validation.width"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.width[0] }}
                </span>
              </div>
              <div class="col-lg-4 mb-4">
                <label>{{ $t('margin') }}</label>
                <input v-model="data.margin" :class="validation && validation.margin ? 'is-invalid' : ''" class="form-control"
                       type="number">
                <span v-if="validation && validation.margin"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.margin[0] }}
                </span>
              </div>
              <div class="col-lg-4 mb-4">
                <label>{{ $t('padding') }}</label>
                <input v-model="data.padding" :class="validation && validation.padding ? 'is-invalid' : ''" class="form-control"
                       type="number">
                <span v-if="validation && validation.padding"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.padding[0] }}
                </span>
              </div>
              <div class="col-lg-4 mb-4">
                <label>{{ $t('label_per_row') }}</label>
                <input v-model="data.label_per_row" :class="validation && validation.label_per_row ? 'is-invalid' : ''" class="form-control"
                       type="number">
                <span v-if="validation && validation.label_per_row"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.label_per_row[0] }}
                </span>
              </div>
              <div class="col-lg-4 mb-4">
                <label>{{ $t('unit_standard') }}</label>
                <select id="unit_standard" v-model="data.unit_standard"
                        :class="validation && validation.unit_standard ? 'is-invalid' : ''" class="custom-select"
                        name="">
                  <option v-for="(row, index) in unit_standard_list" :key="'a'+index" :value="row.slug">{{
                      row.title
                    }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pr-0 pl-0">
        <div class="row">
          <div class="col-lg-6 mt-5">
            <button class="btn btn-primary mr-2" type="reset" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>

    </div>
    <!--end::customer-->
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Vue from "vue";

export default {
  name: "form-items-configuration",
  data() {
    return {
      mainRoute: 'items/items_configuration',
      mainRouteDependency: 'base/dependency',
      data: {
        height: null,
        width: null,
        margin: null,
        padding: null,
        label_per_row: null,
        unit_standard: null,
      },
      size_option: [
        {label: '2*4', id: 1, height: 2, width: 4},
        {label: '2.5*5', id: 2, height: 2.5, width: 5},
        {label: '4*6', id: 3, height: 4, width: 6},
      ],
      size: null,
      unit_standard_list: [],
      validation: null,
    };
  },

  methods: {
    save() {
      ApiService.post(`${this.mainRoute}`, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        Vue.prototype.$postStatus = true;
        this.$successAlert(response.data.message);
      })
          .catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
            Vue.prototype.$postStatus = true;
            this.$errorAlert(error);
          });
    },
    setHeightWidth() {
      let size = this.size_option.find((row) => row.id === this.size);
      this.data.height = size.height;
      this.data.width = size.width;
    },
    async getData() {
      ApiService.get(`${this.mainRoute}`).then((response) => {
        let data = response.data.data;
        this.data = {
          height: data.height,
          width: data.width,
          margin: data.margin,
          padding: data.padding,
          label_per_row: data.label_per_row,
          unit_standard: data.unit_standard,
        };
      }).then(() => {
        this.setSizeDepindOnHeightWidth();
      });
    },
    setSizeDepindOnHeightWidth() {
      let size = this.size_option.find((row) => row.height === this.data.height && row.width === this.data.width);
      this.size = size.id;
    },
    getUnitStandardList() {
      ApiService.get(this.mainRouteDependency + "/unit_standard").then((response) => {
        this.unit_standard_list = response.data.data;
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.item_configuration')},{title: this.$t("MENU.item_label_management")}]);
  },
  created() {
    this.getData();
    this.getUnitStandardList();
  }
};
</script>
